<template>
    <div>
        <nf-breadcrumb :breadList='breadList'></nf-breadcrumb>
        <div class='detailContent'>
            <h2>国家南繁工作领导小组办公室（海南省南繁管理局）联系方式</h2>
            <p class='time'>2013-12-17</p>
            <div class='content'>地址：海南省三亚市吉阳区兆龙西路海南省南繁管理局</div>
            <div class='content'>邮编：572000</div>
            <div class='content'>电话：0898-88296311</div>
            <div class='content'>传真：0898-88296311</div>
            <div class='content'>Eamil：gjnfb@126.com</div>
        </div>
        
    </div>
</template>
<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
export default {
    components: {
        nfBreadcrumb
    },
    data() {
        return {
             breadList: [{
                name: '关于南繁',
                path: '/aboutNanfan'
            }, {
                name: '详情'
            }]
        };
    }
};
</script>
<style lang="less" scoped>
    h2{
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        text-align: center;
    }
    .time{
        color: #5c5c5c;
        font-size: 16px;
        margin:20px 0 20px;
        padding-bottom:38px;
        text-align: center;
        border-bottom:1px solid #d9d9d9;
    }
    .detailContent{
        width:1181px;
        margin:0 auto;
        line-height: 30px;
        font-size: 16px;
    }
    .title{
        color: #333333;
        font-size: 18px;
    }
    .content{
        text-indent: 2em;
        padding-bottom:30px;
    }
    .img{
        text-align: center;
        margin:28px 0;
    }
</style>